.transactions {
  background-color: white;
  height: 200px;
  padding: 2px;
  text-align: justify;
  overflow-y: scroll;
}
.transactions::-webkit-scrollbar {
  width: 0 !important;
}
.datails {
  display: flex;
  flex-direction: row;
  padding: 8px;
}
h4 {
  margin: 2px;
}
p {
  margin: 0px;
}
.sender {
  height: 60px;
  width: 49%;
  text-align: center;
  background-color: white;
  padding: 2px;
  text-align: justify;
  overflow-y: scroll;
  border-radius: 5px;
}
.sender::-webkit-scrollbar {
  width: 0 !important;
}
.Reciever {
  height: 60px;
  width: 49%;
  text-align: center;
  background-color: white;
  padding: 2px;
  text-align: justify;
  overflow-y: scroll;
  border-radius: 5px;
  margin-left: 8px;
}
.Reciever::-webkit-scrollbar {
  width: 0 !important;
}
.buttons {
  padding: 10px;
}
.btns {
  height: 60px;
  width: fit-content;
  padding: 20px;
  border-radius: 5px;
  background-color: #2a4e7f;
  text-align: center;
  margin-left: 8px;
  color: white;
}

textarea {
  width: 100%;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  resize: none;
}

.link_btn {
  width: 100%;
  margin: 5px;
  background-color: #3174ba;
}

@media screen and (max-width: 200px) {
  .transactions {
    background-color: white;
    height: 100px;
    padding: 2px;
    text-align: justify;
  }
  .datails {
    display: flex;
    flex-direction: column;
    padding: 8px;
    align-items: center;
    width: 100%;
  }
  .sender {
    height: 60px;
    width: 100%;
    text-align: center;
    background-color: white;
    font-size: larger;
    padding: 20px;
    border-radius: 5px;
  }
  .Reciever {
    height: 100px;
    width: 100%;
    text-align: center;
    background-color: white;
    font-size: larger;
    padding: 10px;
    border-radius: 5px;
    margin-top: 8px;
  }
  .buttons {
    padding: 10px;
  }
  .btns {
    height: 60px;
    width: 100px;
    padding: 20px;
    border-radius: 5px;
    background-color: #2a4e7f;
    text-align: center;
    margin-left: 8px;
    color: white;
  }
}
