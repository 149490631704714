@import url("https://fonts.googleapis.com/css2?family=Neucha&display=swap");

.crypto {
  --s: 100px; /* control the size */
  --c1: #c3ccaf;
  --c2: #1f1b1c;

  --_s: calc(2 * var(--s)) calc(2 * var(--s));
  --_g: var(--_s) conic-gradient(at 40% 40%, #0000 75%, var(--c1) 0);
  --_p: var(--_s) conic-gradient(at 20% 20%, #0000 75%, var(--c2) 0);
  background: calc(0.9 * var(--s)) calc(0.9 * var(--s)) / var(--_p),
    calc(-0.1 * var(--s)) calc(-0.1 * var(--s)) / var(--_p),
    calc(0.7 * var(--s)) calc(0.7 * var(--s)) / var(--_g),
    calc(-0.3 * var(--s)) calc(-0.3 * var(--s)) / var(--_g),
    conic-gradient(from 90deg at 20% 20%, var(--c2) 25%, var(--c1) 0) 0 0 /
      var(--s) var(--s);
  animation: m 3s infinite;
}
@keyframes m {
  0% {
    background-position: calc(0.9 * var(--s)) calc(0.9 * var(--s)),
      calc(-0.1 * var(--s)) calc(-0.1 * var(--s)),
      calc(0.7 * var(--s)) calc(0.7 * var(--s)),
      calc(-0.3 * var(--s)) calc(-0.3 * var(--s)), 0 0;
  }
  25% {
    background-position: calc(1.9 * var(--s)) calc(0.9 * var(--s)),
      calc(-1.1 * var(--s)) calc(-0.1 * var(--s)),
      calc(1.7 * var(--s)) calc(0.7 * var(--s)),
      calc(-1.3 * var(--s)) calc(-0.3 * var(--s)), 0 0;
  }
  50% {
    background-position: calc(1.9 * var(--s)) calc(-0.1 * var(--s)),
      calc(-1.1 * var(--s)) calc(0.9 * var(--s)),
      calc(1.7 * var(--s)) calc(-0.3 * var(--s)),
      calc(-1.3 * var(--s)) calc(0.7 * var(--s)), 0 0;
  }
  75% {
    background-position: calc(2.9 * var(--s)) calc(-0.1 * var(--s)),
      calc(-2.1 * var(--s)) calc(0.9 * var(--s)),
      calc(2.7 * var(--s)) calc(-0.3 * var(--s)),
      calc(-2.3 * var(--s)) calc(0.7 * var(--s)), 0 0;
  }
  100% {
    background-position: calc(2.9 * var(--s)) calc(-1.1 * var(--s)),
      calc(-2.1 * var(--s)) calc(1.9 * var(--s)),
      calc(2.7 * var(--s)) calc(-1.3 * var(--s)),
      calc(-2.3 * var(--s)) calc(1.7 * var(--s)), 0 0;
  }
}

.crypto {
  height: 100vh;
}

/* fin */
.box {
  background-color: #cbd6e0;
  margin-top: 50px;
  margin-left: 100px;
  margin-right: 100px;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
}
.flex {
  display: flex;
  width: 90%;
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
}
.input {
  width: 100%;
  border-radius: 50px;
  padding: 20px 30px;
  font-size: 25px;
  border: none;
  transition: 0.2s;
  box-shadow: inset 0 0 5px black;
}
.input:focus {
  box-shadow: 0 0 10px 1000px rgba(0, 0, 0, 0.5);
  outline: none;
}
.btn {
  position: absolute;
  width: 50px;
  height: 50px;
  margin: 12px;
  border-radius: 50px;
  right: 0px;
  border: none;
  font-size: 15px;
  background-color: #2f74c0;
  color: white;
  transition: 0.2s all;
  box-shadow: 0 0 10px black;
}
.btn:hover {
  background-color: #388ae2;
}

.btn:active {
  transform: scale(0.8);
  box-shadow: 0 0 5px black;
}

.intro-text {
  padding-top: 20px;
  font-size: larger;
}
@media screen and (max-width: 390px) {
  .crypto {
    justify-content: center;
  }
  .box {
    background-color: #cbd6e0;
    margin-top: 20px;
    border-radius: 5px;
    width: 100%;
    margin-left: 1px !important;
    margin-right: 3px !important;
  }
  .input {
    height: 50px;
    width: 80%;
    border-radius: 15px;
  }
  .flex {
    display: flex;
    flex-direction: row;
  }
  .btn {
    width: 20%;
    background-color: #2a4e7f;
    color: white;
    border-radius: 15px;
    height: 55px;
    font-size: large;
  }
  .intro-text {
    padding-top: 20px;
    font-size: larger;
  }
  li {
    padding: 10px;
  }
}
@media screen and (max-width: 654px) {
  .crypto {
    justify-content: center;
  }
  .box {
    background-color: #cbd6e0;
    margin-top: 20px;
    border-radius: 5px;
    width: 100%;
    margin-left: 1px !important;
    margin-right: 3px !important;
  }
  .input {
  }
}

.heading {
  font-family: "Neucha", cursive;
  font-size: 4em;
  font-weight: 700;
  color: "darkblue";
  width: 100%;
  text-align: center;
  padding-top: 15px;
  color: aliceblue;
}
